import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TuiAlertService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@services/app.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(TuiAlertService)
    private readonly alerts: TuiAlertService,
    private translate: TranslateService,
    private appService: AppService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((catchError: HttpErrorResponse) => {
        const errorObj = catchError.error;
        const status = catchError.status;
        const result = this.errorHandlerByStatus(status, errorObj);
        if (result) {
          return throwError(() => {
            return errorObj;
          });
        }
        return EMPTY;
      }),
    );
  }

  errorHandlerByStatus(status: number, errorObj: any): any {
    this.appService.isLoading.next(false);
    switch (status) {
      case 400:
        return this.status400(errorObj);
      case 401:
        this.status401();
        break;
      case 403:
        this.status403(errorObj);
        break;
      case 404:
        this.status404();
        break;
      case 413:
        this.status413();
        break;
      case 423:
        this.status423(errorObj);
        break;
      case 500:
        this.status500();
        break;
      case 503:
        this.status503();
        break;
      default:
        this.showNotification();
    }
  }

  status400(errorObj: any) {
    const label = this.translate.instant('err400Label');

    const showErrorNotification = (content: string) => {
      this.showNotification(label, content);
    };

    if (errorObj?.detail?.length > 0) {
      errorObj.detail.forEach(showErrorNotification);
    } else if (errorObj?.error) {
      const errorMessage = errorObj.error.message || errorObj.error;
      showErrorNotification(errorMessage);
    }

    return true;
  }

  status401(): void {
    sessionStorage.removeItem(environment.tokenKey);
    window.location.href = '/';
  }

  status403(errorObj: any): void {
    const label = this.translate.instant('err403Label');
    const content = this.translate.instant('err403Content');
    if (errorObj?.error) {
      this.showNotification(label, errorObj?.error);
    } else {
      this.showNotification(label, content);
    }
  }

  status404(): void {
    const label = this.translate.instant('err404Label');
    const content = this.translate.instant('err404Content');
    this.showNotification(label, content);
  }

  status413() {
    const label = this.translate.instant('Error uploading file');
    const content = this.translate.instant('err413Content');
    this.showNotification(label, content);
  }

  status423(errorObj: any): void {
    const content = errorObj?.detail || this.translate.instant('The account is blocked');
    if (sessionStorage.getItem(environment.tokenKey)) {
      sessionStorage.clear();
      window.location.href = `/auth/login/?eventType=deactivate`;
    } else {
      this.showNotification('', content);
    }
  }

  status500(): void {
    const label = this.translate.instant('err500Label');
    const content = this.translate.instant('err500Content');
    this.showNotification(label, content);
  }

  status503(): void {
    const label = this.translate.instant('err503Label');
    const content = this.translate.instant('err503Content');
    this.showNotification(label, content);
  }

  showNotification(
    label: string = 'Error application',
    content: string = '',
    autoClose: boolean | number = 5000,
  ): void {
    this.alerts.open(content, { label, autoClose, status: 'error' }).subscribe();
  }
}
