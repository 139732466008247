import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const lang = this.translate.currentLang || 'en';
    const modifiedRequest: any = this.addAuthToken(request);

    return next.handle(
      modifiedRequest.clone({
        setHeaders: {
          'Accept-Language': lang,
        },
      }),
    );
  }

  addAuthToken(request: HttpRequest<any>) {
    const token = sessionStorage.getItem(environment.tokenKey);
    if (
      request.url.includes('http') ||
      request.url.includes('https') ||
      request.url.includes('i18n')
    ) {
      return request;
    }
    if (
      !request.url.includes('auth/') &&
      !request.url.includes('password_reset/') &&
      !request.url.includes('check_password_reset_token') &&
      !request.url.includes('password_reset_confirm')
    ) {
      return request.clone({
        url: `${environment.apiUrl}${request.url}`,
        setHeaders: {
          Authorization: `Token ${token}`,
        },
      });
    }
    return request.clone({
      url: `${environment.apiUrl}${request.url}`,
    });
  }
}
