import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addObject,
  deleteObjectById,
  updateConnectedStatusForObject,
  updateLastMessageForObjects,
  updateObjectById,
} from '@store/actions/objects.actions';
import { environment } from '../environments/environment';
import { MapService } from '../app/portal/components/map/services/map.service';
import { TuiAlertService } from '@taiga-ui/core';
import { NotificationsHelperService } from '@services/notifications-helper.service';
import { changeListImportObjects } from '@store/actions/import_objects.actions';
import { TranslateService } from '@ngx-translate/core';
import { ObjectsService } from '@services/objects.service';
import {
  addVisibleObjectsIdForCurrentUser,
  deleteVisibleObjectsIdForCurrentUser,
  storeCurrentUser,
} from '@store/actions/current_user.actions';
import { TuiLanguageSwitcher } from '@taiga-ui/i18n';
import { bufferTime, filter, Subject, Subscription } from 'rxjs';
import { updateIsActiveNotifications } from '@store/actions/notifications.actions';
import { map } from 'rxjs/operators';
import { UsersService } from '@services/users.service';

export enum SocketDataEventsE {
  Deactivate = 'deactivate',
  Delete = 'delete',
  Update = 'update',
  SelfUpdate = 'self_update',
  Activate = 'activate',
  Create = 'create',
  ChangePassword = 'change_password',
}

export type ChangeObjectFromSocket = {
  id: number;
  action: SocketDataEventsE;
};

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private messageStreamSubscription$!: Subscription;

  messageStream$ = new Subject<any>();

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    @Inject(TuiLanguageSwitcher) private readonly switcher: TuiLanguageSwitcher,
    private store: Store,
    private noticeHelpS: NotificationsHelperService,
    private mapService: MapService,
    private usersService: UsersService,
    private translate: TranslateService,
    private objectsService: ObjectsService,
  ) {}

  sockets: any = {};

  private userSocket: null | WebSocket = null;

  public isConnected: boolean = false;

  disconnectUserSocket() {
    this.userSocket?.close();
    this.userSocket = null;
    this.isConnected = false;
  }

  connectUserSocket(currentUserInfo: any) {
    if (!this.isConnected) {
      const userId = currentUserInfo.id;
      const bufferTimeValue = currentUserInfo?.visibleObjectsIds?.length >= 500 ? 30000 : 5000;

      const token = sessionStorage.getItem(environment.tokenKey);
      this.userSocket = new WebSocket(
        `${environment.websocketUrl}live-data/${userId}?token=${token}`,
      );
      this.userSocket.onopen = () => {
        console.log(`connected ${userId} - ${new Date()}`);
        this.isConnected = true;

        this.messageStreamSubscription$ = this.messageStream$
          .pipe(
            bufferTime(bufferTimeValue),
            map((messagesArray) => {
              return messagesArray.reduce((acc, message) => {
                acc[message.oid] = message.m;
                return acc;
              }, {});
            }),
            filter((latestMessages) => !!Object.keys(latestMessages).length),
          )
          .subscribe((latestMessages) => {
            this.store.dispatch(updateLastMessageForObjects(latestMessages));
            this.mapService.changeObjectMarkerPos.next(latestMessages);
          });

        // this.messageStreamSubscription$ = this.store
        //   .select(selectCurrentUser)
        //   .pipe(
        //     switchMap((userInfo) => {
        //       console.log(userInfo);
        //       const bufferTimeValue = userInfo.visibleObjectsIds.length >= 500 ? 30000 : 5000;
        //       return this.messageStream$.pipe(
        //         bufferTime(bufferTimeValue),
        //         map((messagesArray) => {
        //           return messagesArray.reduce((acc, message) => {
        //             acc[message.oid] = message.m;
        //             return acc;
        //           }, {});
        //         }),
        //         filter((latestMessages) => {
        //           // console.warn(latestMessages);
        //           return !!Object.keys(latestMessages).length;
        //         }),
        //         finalize(() => {
        //           console.error(this);
        //         }),
        //       );
        //     }),
        //   )
        //   .subscribe({
        //     next: (latestMessages) => {
        //       console.log(latestMessages);
        //       this.store.dispatch(updateLastMessageForObjects(latestMessages));
        //       this.mapService.changeObjectMarkerPos.next(latestMessages);
        //     },
        //     error: (err) => {
        //       console.log('ERROR', err);
        //     },
        //   });
      };
      this.userSocket.onmessage = (data: any) => {
        const socketData = JSON.parse(data.data);
        if (socketData.type === 'live_new_data') {
          this.messageHandler(socketData);
        } else if (socketData.type === 'live_import_data') {
          this.importHandler(socketData);
        } else if (socketData.type === 'notification_counter') {
          this.noticeHelpS.countNotifications.next(this.noticeHelpS.countNotifications.value + 1);
        } else if (socketData.type === 'new_notification_push') {
          this.newNotification(socketData);
        } else if (socketData.type === 'command_result') {
          this.commandResult(socketData);
        } else if (socketData.type === 'objects__refresh') {
          this.objectsRefreshHandler(socketData);
        } else if (socketData.type === 'current_user__refresh') {
          this.currentUserRefreshHandler(socketData);
        } else if (socketData.type === 'notifications__refresh') {
          this.notificationsRefreshHandler(socketData);
        }
      };
      this.userSocket.onclose = () => {
        console.log(`disconnected ${userId} - ${new Date()}`);
        this.isConnected = false;

        const token = sessionStorage.getItem(environment.tokenKey);
        if (token) {
          this.connectUserSocket(currentUserInfo);
        }
        this.messageStreamSubscription$?.unsubscribe();
      };
    }
  }

  newNotification(socketData: any) {
    if (socketData.showPush) {
      const data = socketData.data;
      const content = `${data.text}`;
      const label = `${data.notificationName}: ${data.objectName}`;

      this.alerts
        .open(content, {
          label: label,
          autoClose: 6000,
          status: 'info',
        })
        .subscribe();
    }
  }

  messageHandler(data: any) {
    const newMessage = data.data?.type === 'nm';
    const connectedStatus = data.data?.type === 'cs';
    if (newMessage) {
      this.messageStream$.next(data.data);
    }
    if (connectedStatus) {
      this.store.dispatch(updateConnectedStatusForObject(data.data));
    }
  }

  commandResult(data: any) {
    const command = data.data.command;
    const msg =
      this.translate.instant('Command') +
      ' "' +
      command.command.name +
      '" ' +
      this.translate.instant('was sent to object') +
      ' "' +
      command.objectName +
      '"';
    this.alerts.open(msg, { status: 'success' }).subscribe();
  }

  importHandler(data: any) {
    this.store.dispatch(changeListImportObjects({ createdObject: data.data }));
    if (data.data?.status === 'DONE') {
      this.alerts
        .open(this.translate.instant('Import is finished'), { status: 'success' })
        .subscribe();
    }
  }

  objectsRefreshHandler(data: any) {
    const objectId = data?.data?.entityId || null;
    const objectInfo = data?.data?.entityData || null;
    const eventType = data?.data?.eventType || null;

    if (eventType === SocketDataEventsE.Update && objectInfo) {
      this.store.dispatch(updateObjectById({ updatedObject: objectInfo }));

      this.objectsService.changeObjectFromSocket$.next({
        id: objectId,
        action: eventType,
      });
    } else if (
      [SocketDataEventsE.Create, SocketDataEventsE.Activate].includes(eventType) &&
      objectInfo
    ) {
      this.store.dispatch(addObject({ createdObject: objectInfo }));

      if (objectInfo?.isDisplayed) {
        this.store.dispatch(addVisibleObjectsIdForCurrentUser({ objectId: objectId }));
      }
    } else if (
      [SocketDataEventsE.Delete, SocketDataEventsE.Deactivate].includes(eventType) &&
      objectId
    ) {
      this.objectsService.changeObjectFromSocket$.next({
        id: objectId,
        action: eventType,
      });

      this.store.dispatch(deleteObjectById({ deletedObjectId: objectId }));
      this.mapService.deleteObjectMarker.next(objectId);

      this.store.dispatch(deleteVisibleObjectsIdForCurrentUser({ objectId: objectId }));
    }
  }

  currentUserRefreshHandler(data: any) {
    const currentUserInfo = data?.data?.entityData || null;
    const eventType:
      | SocketDataEventsE.ChangePassword
      | SocketDataEventsE.Update
      | SocketDataEventsE.SelfUpdate
      | SocketDataEventsE.Deactivate
      | SocketDataEventsE.Delete = data?.data?.eventType || null;

    if (eventType === SocketDataEventsE.SelfUpdate && currentUserInfo) {
      this.store.dispatch(storeCurrentUser({ currentUser: currentUserInfo }));

      this.translate.use(currentUserInfo.language);
      this.switcher.setLanguage(currentUserInfo.language === 'ru' ? 'russian' : 'english');
      localStorage.setItem('lang', currentUserInfo.language);
      sessionStorage.setItem(
        'timezone',
        `UTC${currentUserInfo.timezone >= 0 ? '+' : ''}${currentUserInfo.timezone}`,
      );
    }

    if (eventType === SocketDataEventsE.Update && currentUserInfo) {
      this.store.dispatch(storeCurrentUser({ currentUser: currentUserInfo }));

      this.alerts
        .open(this.translate.instant('notificationOnUpdateCurrentUserBySocket'), {
          status: 'warning',
          autoClose: false,
        })
        .subscribe();
    }

    if (
      [
        SocketDataEventsE.Deactivate,
        SocketDataEventsE.Delete,
        SocketDataEventsE.ChangePassword,
      ].includes(eventType)
    ) {
      if (
        eventType === SocketDataEventsE.ChangePassword &&
        this.usersService.selfChangePassword$.getValue()
      ) {
        this.usersService.selfChangePassword$.next(false);
        return;
      }

      sessionStorage.clear();
      window.location.href = `/auth/login/?eventType=${eventType}`;
    }
  }

  notificationsRefreshHandler(data: any) {
    const eventType = data?.data?.eventType;
    const entityId = data?.data?.entityId;
    if (eventType === SocketDataEventsE.Activate && entityId) {
      this.store.dispatch(updateIsActiveNotifications({ isActive: true, id: entityId }));
    } else if (eventType === SocketDataEventsE.Deactivate && entityId) {
      this.store.dispatch(updateIsActiveNotifications({ isActive: false, id: entityId }));
    }
  }
}
