import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { PaginatorService } from '@common/services/paginator.service';
import { setLastPageStateMessages } from '@store/actions/messages.actions';
import { Store } from '@ngrx/store';
import { TuiAlertService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { AvailableMessagesParam, AvailableMessagesTypes } from '@store/reducers/messages.reducer';
import { SortOrder } from '@common/utils/search-sort';

export type MessagesStatParams = {
  objectId: number;
  startDate: string;
  endDate: string;
  messageType: string;
};

export type MessagesRequestParams = {
  objectId: number;
  startDate: string;
  endDate: string;
  page: number;
  pageSize: number | null;
  messageType: AvailableMessagesTypes;
  param: AvailableMessagesParam;
  sort?: SortOrder;
  filterServiceMessages?: boolean;
};

export type ResponseStats = {
  count: number;
  createdAt: string;
  avgSpeed: number | null;
  distance: number | null;
  duration: number | null;
  maxSpeed: number | null;
};

export type MessagesDataT = {
  stat: ResponseStats;
  messages: any;
  object: any;
  type: AvailableMessagesParam;
  datetimePeriod: string;
  requestParams: MessagesRequestParams;
};

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private httpClient: HttpClient,
    private store: Store,
    private translate: TranslateService,
    public paginatorService: PaginatorService,
  ) {}

  // Временная дичь
  isInitializeGraph = false;

  public errorFromMessagesResult$ = new Subject();

  public messagesData = new BehaviorSubject<MessagesDataT | null>(null);

  public addresses: any = {};

  public changeLegends = new BehaviorSubject<any>([]);

  public currentView = new BehaviorSubject('table');
  public currentType = new BehaviorSubject<AvailableMessagesParam>(AvailableMessagesParam.Raw);
  public currentMessageType = new BehaviorSubject<AvailableMessagesTypes>(
    AvailableMessagesTypes.WithData,
  );

  public objectForFastMessages: any = null;
  public objectForFastMessages$ = new BehaviorSubject<any>(null);

  getMessages(params: MessagesRequestParams) {
    this.currentType.next(params.param);
    this.currentMessageType.next(params.messageType);

    const body = {
      objectId: params.objectId,
      startDate: params.startDate,
      end_date: params.endDate,
      messagesType: params.messageType,
      messagesParam: params.param,
      page: params.page,
      page_size: params.pageSize,
      sort: params?.sort || SortOrder.Desc,
      filterServiceMessages: params?.filterServiceMessages || false,
    };
    return this.httpClient.post(`messages/get`, body);
  }

  getMessagesStat(params: MessagesStatParams): Observable<ResponseStats> {
    return this.httpClient
      .post<ResponseStats>(`messages/stat`, {
        objectId: params.objectId,
        startDatetime: params.startDate,
        endDatetime: params.endDate,
        messagesType: params.messageType,
      })
      .pipe(
        switchMap((stat) => {
          if ((stat?.count || 0) === 0) {
            this.alerts
              .open(this.translate.instant('NO_DATA_AVAILABLE_FOR_SELECTED_PERIOD'), {
                status: 'error',
                autoClose: 6000,
              })
              .subscribe();
            return throwError(() => 'no count - no data');
          } else {
            return of(stat);
          }
        }),
        tap((res) => {
          this.store.dispatch(
            setLastPageStateMessages({
              newPageState: {
                stats: res,
              },
            }),
          );
        }),
      );
  }
}
